<template>
  <v-row justify="center" v-if="dialog">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      content-class="template-email-dialog"
      transition="dialog-bottom-transition"
    >
      <v-card class="d-flex flex-column">
        <v-toolbar dark color="primary flex-shrink-1 flex-grow-0">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            email?.nome ?? "Novo template de email"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="preview = !preview" v-if="email.conteudo">
              {{
                preview
                  ? `${readonly ? "Visualizar" : "editar"} código`
                  : "Visualizar"
              }}
            </v-btn>
            <v-btn
              :loading="carregandoValidar"
              dark
              text
              @click="validarTemplate()"
              v-if="!templateValido && templateEditado"
            >
              Validar
            </v-btn>
            <v-btn
              v-if="templateEditado && templateValido"
              dark
              text
              @click="salvarTemplate()"
            >
              Salvar
            </v-btn>
            <v-btn
              v-if="
                email.status == 'RASCUNHO' && !templateEditado && templateValido
              "
              dark
              text
              @click="publicarTemplate()"
            >
              Publicar
            </v-btn>

            <!-- <v-btn icon @click="toggleTheme()">
              <v-icon>mdi-theme-light-dark</v-icon></v-btn
            > -->
          </v-toolbar-items>
        </v-toolbar>
        <div class="d-flex grow">
          <v-form
            class="crud shrink pt-2"
            ref="form"
            v-model="valid"
            :disabled="readonly"
          >
            <v-row class="pa-2 pb-0">
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pb-0">
                <v-btn
                  v-if="
                    (email.status == 'RASCUNHO' && templateValido) || readonly
                  "
                  text
                  small
                  color="primary"
                  @click="openRender()"
                  :loading="loadingRender"
                >
                  Renderizar
                </v-btn>
                <v-btn
                  v-if="!readonly"
                  text
                  small
                  color="primary"
                  @click="abrirVariaveis = true"
                >
                  Variáveis
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="pa-2">
              <v-col cols="12">
                <v-text-field
                  v-model="email.nome"
                  maxlength="256"
                  counter="256"
                  :rules="[() => !!email.nome || 'Campo obrigatório']"
                  outlined
                  label="Nome"
                  :error-messages="erroNome"
                  :dark="theme == 'vs-dark'"
                  ref="email.nome"
                  @focus="storeActiveInput('email.nome')"
                  @click="storeCursorPosition($event)"
                  @input="storeCursorPosition($event)"
                  @keyup="storeCursorPosition($event)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="email.assunto"
                  maxlength="256"
                  counter="256"
                  :rules="[() => !!email.assunto || 'Campo obrigatório']"
                  outlined
                  :error="errosNoAssunto?.length > 0"
                  :error-messages="errosNoAssunto"
                  label="Assunto"
                  class="mb-0"
                  :dark="theme == 'vs-dark'"
                  ref="email.assunto"
                  @focus="storeActiveInput('email.assunto')"
                  @click="storeCursorPosition($event)"
                  @input="storeCursorPosition($event)"
                  @keyup="storeCursorPosition($event)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  ref="email.observacao"
                  @focus="storeActiveInput('email.observacao')"
                  @click="storeCursorPosition($event)"
                  @input="storeCursorPosition($event)"
                  @keyup="storeCursorPosition($event)"
                  v-model="email.observacao"
                  outlined
                  :rules="[() => !!email.observacao || 'Campo obrigatório']"
                  label="Observação"
                >
                </v-textarea>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="email.tipo_regua"
                  outlined
                  @change="trocarVariaveis"
                  :rules="[() => !!email.tipo_regua || 'Campo obrigatório']"
                  :loading="carregandoVariaveis"
                  label="Tipo da régua"
                  :items="tiposRegua()"
                  item-value="tipo"
                  item-text="tipo"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.tipo | tipoRegua }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.tipo | tipoRegua }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row
              class="mt-0 pb-4 px-4"
              v-if="errosRenderizacao?.erros?.length"
            >
              <v-col cols="12" class="pt-0"
                ><h4>Erros de renderização</h4></v-col
              >
              <v-col
                cols="auto"
                class="pa-1"
                v-for="(erro, index) in errosRenderizacao.erros.filter(
                  (item) => item.lineNumber !== null
                )"
                :key="index"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      x-small
                      @click="irParaErro(erro)"
                      outlined
                      rounded
                      color="error"
                      >{{ erro.substring }}</v-btn
                    >
                  </template>
                  <span>{{ erro.motivo }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                v-for="(erro, index) in errosRenderizacao.erros.filter(
                  (item) => item.lineNumber === null
                )"
                :key="index"
                cols="12"
                class="pt-4 pa-0"
              >
                <v-alert outlined type="error" text dense>
                  {{ erro.motivo }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row
              class="mt-0 pb-4 pt-4 px-4"
              v-if="variaveisComErro?.erros?.length"
            >
              <v-col cols="12" class="pt-0"><h4>Váriaveis com erro</h4></v-col>
              <v-col
                cols="auto"
                class="pa-1"
                v-for="(variavel, index) in variaveisComErro.erros"
                :key="index"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      x-small
                      @click="irParaVariavel(variavel)"
                      rounded
                      outlined
                      color="error"
                      >{{ variavel.substring }}</v-btn
                    >
                  </template>
                  <span>{{ variavel.motivo }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row class="mt-0 px-4" v-if="variaveis?.length">
              <v-col cols="12" class="px-2"><h4>Váriaveis em uso</h4></v-col>
              <v-col
                cols="auto"
                class="pa-1"
                v-for="(variavel, index) in variaveis"
                :key="index"
              >
                <v-btn
                  x-small
                  @click="irParaVariavel(variavel)"
                  rounded
                  outlined
                  color="primary"
                  >{{ variavel.substring }}</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <TabelaAnexos :anexos="anexo"></TabelaAnexos>
            </v-row>
          </v-form>
          <v-divider vertical></v-divider>
          <div class="editor grow">
            <iframe
              v-if="preview && email.conteudo"
              class="frame"
              :srcdoc="email.conteudo"
              frameborder="0"
            ></iframe>
            <div
              v-show="email?.conteudo != null && !preview"
              class="editor__container"
            >
              <vue-monaco-editor
                @mount="handleMount"
                ref="monacoEditor"
                :options="codeEditorOptions"
                :line="1"
                language="html"
                v-model="email.conteudo"
                :theme="theme"
              ></vue-monaco-editor>
            </div>
            <div
              @dragover.prevent="handleDragOver"
              @dragleave="handleDragLeave"
              @drop="handleDrop"
              :class="{ 'drag-over': isDragOver }"
              class="drag-area fill-height d-flex flex-column align-center grow"
            >
              <div class="d-flex flex-column justify-center grow mb-16">
                <v-icon color="primary" size="150">mdi-file-code</v-icon>
                <h2 class="text-center">
                  Arraste seu código ou
                  <a @click="email.conteudo = ''">cole no editor</a>
                </h2>
              </div>
            </div>
            <div
              class="editor__after fade-transition"
              v-if="abrirVariaveis"
              transition="fade-transition"
            ></div>
            <!-- <CodeEditor v-model="email.conteudo"  lang="HTML" theme="github" :wrap_code="true" ></CodeEditor> -->
          </div>
          <div
            class="variaveis elevation-10"
            v-if="abrirVariaveis"
            key="1"
            transition="scale-transition"
          >
            <ListaVariaveis
              transition="scale-transition"
              class="fill-height"
              :abrirVariaveis="abrirVariaveis"
              @concatenaVariavel="concatenaVariavel"
              @fecharVariaveis="abrirVariaveis = false"
            />
          </div>
        </div>
      </v-card>
    </v-dialog>
    <DialogDeletaAnexo ref="DialogDeletaAnexo"></DialogDeletaAnexo>
    <DialogAbrePreview ref="DialogAbrePreview"></DialogAbrePreview>
  </v-row>
</template>
<script>
import emailService from "../../../services/emails";
import ListaVariaveis from "../../reguas/components/ListaVariaveis.vue";
import TabelaAnexos from "../components/TabelaAnexos.vue";
import DialogDeletaAnexo from "./DIalogDeletaAnexo.vue";
import DialogAbrePreview from "./DialogAbrePreview.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    DialogDeletaAnexo,
    ListaVariaveis,
    DialogAbrePreview,
    TabelaAnexos,
  },
  data: () => {
    return {
      initialName: null,
      readonly: false,
      dialog: false,
      notifications: false,
      carregandoVariaveis: false,
      abrirVariaveis: false,
      sound: true,
      widgets: false,
      mostrarAnexo: false,
      email: {
        nome: null,
        assunto: null,
        observacao: null,
      },
      anexo: {
        url_download: null,
        nome_arquivo: null,
      },
      theme: "vs-light",
      preview: true,
      valid: null,
      carregandoValidar: false,
      templateEditado: false,
      editor: null,
      isDragOver: false,
      templateValido: false,
      anexoReadOnly: false,
      loadingRender: false,
      lastActiveInput: null,
      cursorPosition: 0,
      files: [],
      erros: null,
      tipoArquivo: [
        {
          text: "Application/pdf",
          value: "application/pdf",
        },
      ],
      templateEditado: false,
      codeEditorOptions: {
        readOnly: false,
        glyphMargin: true,
        isWholeLine: true,
        overviewRulerLanes: 0,
        minimap: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    ...mapActions("variavelRegua", { pegarVariaveis: "pegar" }),
    ...mapActions("emails", [
      "validarEmail",
      "publicar",
      "salvar",
      "salvarAnexo",
      "pegarAnexo",
      "removeAnexo",
    ]),
    ...mapActions("snackbar", ["showSnackBar"]),
    storeActiveInput(refName) {
      this.lastActiveInput = refName;
    },
    storeCursorPosition(event) {
      this.cursorPosition = event.target?.selectionStart;
    },
    async openRender() {
      this.loadingRender = true;
      const variaveis = this.criaJsonVariaveis();
      const payload = await this.$refs.DialogAbrePreview.open(variaveis);
      if (!payload) {
        this.loadingRender = false;
        return;
      }
      try {
        const link = await emailService.renderizar(this.email.id, {
          tipo_regua: this.email.tipo_regua,
          empresa_id: this.empresaSelecionada.id,
          variaveis: { ...payload },
        });
        const route = this.$router.resolve({ name: "preview" });
        sessionStorage.setItem("template", JSON.stringify(link.data));
        window.open(route.href, "_blank");
      } catch (error) {
        this.showSnackBar({ text: "Falha ao renderizar o template" });
        console.error(error);
      } finally {
        this.loadingRender = false;
      }
    },
    criaJsonVariaveis() {
      const vars = {};
      this.variaveis.forEach((variavel) => {
        const chave = variavel.substring.replace(/\s/g, "");
        const valor =
          this.todasVariaveis.find((variavel) => {
            return variavel.nome == chave;
          })?.exemplo || "";
        Object.assign(vars, { [chave]: valor });
      });
      return vars;
    },
    async open(
      email = { conteudo: null, tipo_template: "JINJA" },
      readonly = false
    ) {
      this.codeEditorOptions.readOnly = readonly;
      this.readonly = readonly;
      this.email = structuredClone(email);
      if (email.nome) this.initialName = email.nome;
      const results = await Promise.all([
        this.pegarVariaveis({
          tipo_regua: email.tipo_regua,
          empresa_id: this.empresaSelecionada.id,
        }),
        this.pegarAnexo(this.email?.id),
      ]);
      this.anexo = results[1] || {};
      this.$nextTick(() => {
        this.dialog = true;
      });
      if (this.anexo.id) this.anexoReadOnly = true;
      if (!this.email.conteudo) this.preview = false;
      if (this.email.status == "RASCUNHO") this.templateValido = true;
    },
    async trocarVariaveis() {
      this.carregandoVariaveis = true;
      await this.pegarVariaveis({
        tipo_regua: this.email.tipo_regua,
        empresa_id: this.empresaSelecionada.id,
      });
      this.carregandoVariaveis = false;
    },
    async validarTemplate() {
      const valid = await this.$refs.form.validate();
      if (!valid) return;
      this.carregandoValidar = true;
      const payload = structuredClone(this.email);
      try {
        await this.validarEmail(payload);
        this.templateValido = true;
        this.erros = null;
        this.showSnackBar({
          text: `Template validado com sucesso!`,
        });
      } catch (error) {
        if (error?.response?.status == 424) {
          this.erros = error.response?.data?.erros;
          this.showSnackBar({
            text: `Falha ao validar, ${
              this.erros?.length > 1
                ? this.erros?.length + " erros encontrados"
                : this.erros?.length + " erro encontrado"
            } `,
          });
        } else console.error(error);
      } finally {
        this.carregandoValidar = false;
      }
    },
    irParaVariavel(variavel) {
      if (variavel.substring) {
        this.preview = false;
        // Set the selection to the position
        this.editor.setSelection(
          new monaco.Range(
            variavel.lineNumber,
            variavel.column,
            variavel.lineNumber,
            variavel.column + variavel.substring.length
          )
        );

        // Optionally, reveal the position within the viewport
        const revealPosition = {
          startLineNumber: variavel.lineNumber,
          startColumn: variavel.column, // Adjust the column with the offset
          endLineNumber: variavel.lineNumber,
          endColumn: variavel.column + variavel.substring.length, // Adjust the column with the offset
        };
        this.editor.revealRangeInCenter(revealPosition);
      } else {
        console.warn("Word not found in the editor.");
      }
    },
    irParaErro(erro) {
      if (erro.substring) {
        this.preview = false;
        // Set the selection to the position
        this.editor.setSelection(
          new monaco.Range(erro.lineNumber, 0, erro.lineNumber, 0)
        );

        // Optionally, reveal the position within the viewport
        const revealPosition = {
          startLineNumber: erro.lineNumber,
          startColumn: 0, // Adjust the column with the offset
          endLineNumber: erro.lineNumber,
          endColumn: 0, // Adjust the column with the offset
        };
        this.editor.revealRangeInCenter(revealPosition);
      } else {
        console.warn("Line not found in the editor.");
      }
    },
    async handleMount(editor) {
      this.editor = editor;
      const email = this.email;
      monaco.languages.registerCompletionItemProvider("html", {
        triggerCharacters: [
          "{",
          "}",
          " ",
          ,
          ...Array.from(
            "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
          ),
        ],
        provideCompletionItems: (model, position) => {
          // Get the text until the current position
          const textUntilPosition = model.getValueInRange({
            startLineNumber: 1,
            startColumn: 1,
            endLineNumber: position.lineNumber,
            endColumn: position.column,
          });
          // Check if the cursor is inside {{ }}
          const match = textUntilPosition.match(/\{\{([^\}]*?)$/);
          if (!match) {
            return { suggestions: [] };
          }
          const input = match[1].trim();
          // Provide Suggestions
          const suggestions = [];
          const variaveis = this.todasVariaveis;
          variaveis?.forEach((variavel) => {
            suggestions.push({
              label: variavel.nome,
              kind: monaco.languages.CompletionItemKind.Text,
              insertText: variavel.nome,
              range: {
                startLineNumber: position.lineNumber,
                startColumn: match.index + 3,
                endLineNumber: position.lineNumber,
                endColumn: position.column,
              },
            });
          });
          const filteredSuggestions =
            suggestions.filter((suggestion) => {
              return suggestion.insertText.includes(input);
            }) || suggestions;
          return {
            suggestions: filteredSuggestions,
          };
        },
      });
    },
    concatenaVariavel(variavel) {
      try {
        const oldValue = this.$refs[this.lastActiveInput]?.value || "";
        const variavelJinja = `{{${variavel.nome}}}`;
        const newValue = `${
          oldValue?.slice(0, this.cursorPosition) || ""
        }${variavelJinja}${oldValue?.slice(this.cursorPosition) || ""}`;

        const objeto = this.lastActiveInput.split(".")[0];
        const prop = this.lastActiveInput.split(".")[1];
        this.$set(this[objeto], prop, newValue);
      } catch (error) {
        this.showSnackBar({ text: "Falha ao adicionar variável" });
        console.error(error);
      }
    },
    toggleTheme() {
      if (this.theme == "vs-dark") {
        this.$vuetify.theme.dark = false;
        this.theme = "vs-light";
      } else {
        this.$vuetify.theme.dark = true;
        this.theme = "vs-dark";
      }
    },
    handleDragOver(event) {
      event.preventDefault();
      this.isDragOver = true;
    },
    handleDragLeave(event) {
      event.preventDefault();
      this.isDragOver = false;
    },
    handleDrop(event) {
      event.preventDefault();
      this.isDragOver = false;
      const files = event.dataTransfer.files;
      if (files.length === 1 && files[0].type === "text/html") {
        const reader = new FileReader();
        reader.onload = () => {
          this.email.conteudo = reader.result;
        };
        reader.readAsText(files[0]);
      } else {
        alert("Please drop a single HTML file.");
      }
    },
    async publicarTemplate() {
      try {
        this.carregandoAcao = true;
        const response = await this.publicar(this.email);
        this.$emit("atualizaTemplate", response);
        this.erros = null;
        this.dialog = false;
        this.showSnackBar({ text: `Template publicado com sucesso!` });
      } catch (error) {
        if (error?.response?.status == 424) {
          this.preview = false;
          this.erros = error.response.data.erros;
          this.showSnackBar({
            text: `Falha ao publicar, ${
              this.erros?.length > 1
                ? this.erros?.length + " erros encontrados"
                : this.erros?.length + " erro encontrado"
            } `,
          });
        }
        this.templateValido = false;
      } finally {
        this.carregandoAcao = false;
      }
    },
    async salvarTemplate() {
      try {
        this.carregandoAcao = true;
        const response = await this.salvar({
          payload: {
            conteudo: this.email.conteudo,
            assunto: this.email.assunto,
            observacao: this.email.observacao,
            nome: this.email.nome,
            tipo_regua: this.email.tipo_regua,
            tipo_template: this.email.tipo_template || "jinja",
          },
          id: this.email.id,
        });
        if (this.anexo.url_download) {
          const anexo = await this.salvarAnexo({
            ...this.anexo,
            template_email_id: response.id,
          });
          this.anexo = anexo;
          this.anexoReadOnly = true;
        }
        this.email = response;
        this.erros = null;
        this.$emit("atualizaTemplate", response);
        this.showSnackBar({ text: `Template salvo com sucesso!` });
        this.$nextTick(() => {
          this.templateEditado = false;
          this.templateValido = true;
        });
      } catch (error) {
        if (error?.response?.status == 424) {
          this.preview = false;
          this.erros = error.response.data.erros;
          this.showSnackBar({
            text: `Falha ao salvar, ${
              this.erros?.length > 1
                ? this.erros?.length + " erros encontrados"
                : this.erros?.length + " erro encontrado"
            } `,
          });
        } else {
          console.error(error);
        }
        this.templateValido = false;
      } finally {
        this.carregandoAcao = false;
      }
    },
    async removerAnexo() {
      const decision = await this.$refs.DialogDeletaAnexo.open();
      if (!decision) return;
      try {
        await this.removeAnexo(this.anexo.id);
        this.anexo = {};
        this.anexoReadOnly = false;
      } catch (error) {
        this.showSnackBar({
          text: `Falha ao remover o anexo`,
        });
        console.error(error);
      }
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    ...mapGetters("reguas", ["tiposRegua"]),
    ...mapGetters("variavelRegua", { todasVariaveis: "variaveis" }),

    modelMarkers() {
      const variavelMarkers = this.variaveisComErro?.markers
        ? [...this.variaveisComErro?.markers]
        : [];
      const renderMarkers = this.errosRenderizacao?.markers
        ? [...this.errosRenderizacao?.markers]
        : [];

      return [...variavelMarkers, ...renderMarkers];
    },

    variaveisComErro() {
      const lines = this.email?.conteudo?.split("\n");
      const variaveisComErro = [];
      let markers = [];

      if (!lines || !this.erros) return null;

      this.erros?.forEach((erro) => {
        if (erro.tipo_erro != "VARIAVEL_CONTEUDO") return;
        erro.column = lines[erro.linha_do_erro - 1]?.indexOf(erro.variavel) + 1;
        variaveisComErro.push({
          substring: erro.variavel,
          lineNumber: erro.linha_do_erro,
          column: erro.column,
          motivo: erro.motivo_erro,
        });
        markers.push({
          startLineNumber: erro.linha_do_erro,
          endLineNumber: erro.linha_do_erro,
          startColumn: erro.column,
          endColumn: erro.column + erro.variavel.length,
          message: erro.motivo_erro,
          severity: monaco.MarkerSeverity.Error,
        });
      });

      return { erros: variaveisComErro, markers: markers };
    },
    errosRenderizacao() {
      const lines = this.email?.conteudo?.split("\n");
      const errosRenderizacao = [];
      let markers = [];

      if (!lines || !this.erros) return null;

      this.erros?.forEach((erro) => {
        if (erro.tipo_erro != "JINJA") return;
        if (!erro.linha_do_erro) {
          errosRenderizacao.push({
            substring: `linha ${erro.linha_do_erro}`,
            lineNumber: null,
            motivo: erro.motivo_erro,
          });
          return;
        }

        const comecoLinha =
          lines[erro.linha_do_erro - 1].indexOf(
            lines[erro.linha_do_erro - 1].trim()
          ) + 1;

        const finalLinha = lines[erro.linha_do_erro - 1].length + 1;

        errosRenderizacao.push({
          substring: `linha ${erro.linha_do_erro}`,
          lineNumber: erro.linha_do_erro,
          motivo: erro.motivo_erro,
          column: 0,
        });

        markers.push({
          startLineNumber: erro.linha_do_erro,
          endLineNumber: erro.linha_do_erro,
          message: erro.motivo_erro,
          severity: monaco.MarkerSeverity.Error,
          startColumn: comecoLinha,
          endColumn: finalLinha,
        });
      });

      return { erros: errosRenderizacao, markers: markers };
    },
    variaveis() {
      const regex = /{{(.*?)}}/g;
      const substringsWithLine = [];
      const lines = this.email?.conteudo?.split("\n");
      if (!lines) return;

      lines.forEach((line, lineNumber) => {
        let match;
        while ((match = regex.exec(line)) !== null) {
          const column = match.index + 3;
          substringsWithLine.push({
            substring: match[1],
            lineNumber: lineNumber + 1,
            column: column,
          });
        }
      });

      return substringsWithLine.filter((variavel) => {
        return !this.variaveisComErro?.erros?.some((variavelComErro) => {
          return variavelComErro.substring == variavel.substring;
        });
      });
    },
    errosNoAssunto() {
      return (
        this.erros
          ?.filter((erro) => erro.tipo_erro == "VARIAVEL_ASSUNTO")
          ?.map((erro) => {
            return erro.motivo_erro.replace(
              "Variavel",
              `Variavel {{${erro.variavel}}}`
            );
          }) ?? []
      );
    },
    erroNome() {
      return this.erros?.some((erro) => erro.tipo_erro == "NOME")
        ? ["Nome em uso por outro template, favor escolher outro!"]
        : null;
    },
  },
  watch: {
    dialog(newValue) {
      const queryAtual = { ...this.$route.query };
      delete queryAtual.template;
      if (!newValue) {
        this.$router.replace({ query: { ...queryAtual } });
        this.email = {};
        this.erros = null;
        this.templateEditado = false;
        this.$vuetify.theme.dark = false;
        this.$emit("close");
      } else {
        this.$router
          .replace({
            query: { ...queryAtual, template: this.email.id || "novo" },
          })
          .catch(() => {});
      }
    },
    modelMarkers: {
      deep: true,
      handler(newValue) {
        monaco.editor.setModelMarkers(this.editor.getModel(), "owner", []);
        monaco.editor.setModelMarkers(
          this.editor.getModel(),
          "owner",
          newValue
        );
      },
    },
    email: {
      deep: true,
      handler() {
        if (this.dialog) {
          this.templateEditado = true;
          this.templateValido = false;
        }
      },
    },
    anexo: {
      deep: true,
      handler() {
        if (this.dialog) {
          this.templateValido = false;
          this.templateEditado = true;
        }
      },
    },
    "email.assunto": {
      deep: true,
      handler() {
        if (this.dialog) {
          this.templateEditado = true;
          this.templateValido = false;
        }
      },
    },
  },
};
</script>
<style lang="scss">
.editor {
  width: auto;
  max-height: calc(100vh - 64px);
  &__after {
    width: 100vw;
    height: calc(100vh - 64px);
    background: #0000007a;
    position: absolute;
    top: 64px;
  }
}
.content {
  height: 100%;
}

.template-email-dialog {
  max-height: 100vh !important;
  overflow: hidden !important;
}
.crud {
  min-width: 590px;
  max-width: 590px;
  max-height: calc(100vh - 64px);
  overflow: auto;
  overflow-x: hidden;
  scrollbar-color: transparent white;
  scrollbar-gutter: stable both-edges;
  transition: scrollbar-color 0.5s ease;
  &:hover {
    scrollbar-color: lightgrey white;
    scrollbar-arrow-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    color: red;
  }
}
.myGlyphMarginClass {
  background: red;
}
.myContentClass {
  /* Make sure to use transparent colors for the selection to work */
  background: rgba(173, 216, 230, 0.5);
}
.drag-area {
  border: 6px dashed #ccc;
  background-color: #f0f0f096;
}
.drag-area.drag-over {
  background-color: #f0f0f06e;
  border-color: #999;
  border: 6px dashed #353535;
  opacity: 0.3;
}
.frame {
  width: 100%;
  height: calc(100vh - 64px);
}
.editor__container {
  height: calc(100vh - 64px);
}
.variaveis {
  position: absolute;
  right: 0px;
}
.variaveis {
  position: absolute;
  right: 0px;
  height: calc(100vh - 64px);
  background: white;
  transform: background-color ease 3s;
  .listaVariaveis {
    height: 100%;
  }
}
</style>
<style lang="scss">
.variaveis {
  .v-list {
    display: flex;
    flex-direction: column;
    height: calc(100% - 64px);
  }
  .ml-variavel-mensagem {
    height: unset !important;
    overflow: auto;
  }
}
</style>
